import React from 'react';
import loadable from '@loadable/component';
import { ProductCard } from 'react-ui-cards';
import GlobalStyles from '../../globalStyles';
import { Row, Col } from 'antd';

import Introduction from '../../content/introduction.json';
import OurAppsBlock from '../../content/ourAppsBlock.json';
import AboutUsBlock from '../../content/aboutUsBlock.json';
import ContactBlock from '../../content/contactBlock.json';

const ContentBlock = loadable(() => import('../../components/ContentBlock'));
const MiddleBlock = loadable(() => import('../../components/MiddleBlock'));
const Container = loadable(() => import('../../common/Container'));
const ScrollToTop = loadable(() => import('../../common/ScrollToTop'));

const Home = () => {
  return (
    <Container>
      <GlobalStyles/>
      <ScrollToTop />
      <ContentBlock
        type="right"
        first="true"
        title={Introduction.title}
        content={Introduction.text}
        button={Introduction.button}
        icon="app-peeps.svg"
      />
      <hr/>
      <div id="OurApps"></div>
      <MiddleBlock
        title={OurAppsBlock.title}
        content={OurAppsBlock.text}
      />
      {/* TODO move product card into a component and text to content/products.json 
          TODO tweak this row/col to scale properly for mobile devices too */}
      <Row type="flex" justify="center" align="middle">
        <Col lg={8} md={10} sm={24} xs={24}>
        <ProductCard
            photos={[
              '/img/showcase-wow.png',
              '/img/showcase-wow1.png',
              '/img/showcase-wow2.png'
            ]}
            productName='Wheel of What'
            description='BUILD your own wheels with whatever choices you need help in deciding. Then SPIN the wheel and watch!'
            buttonText='Click for More Info'
            url='https://wheelofwhat.mondocoolstudios.com'
        />
        </Col>
        <Col lg={8} md={10} sm={24} xs={24}>
        <ProductCard
            photos={[
              '/img/showcase-flipthat.png'
            ]}
            productName='Flip That!'
            description='Flip That! is a 3D coin flipping app. Need help deciding between 2 things or a way to resolve disputes?'
            buttonText='Click for More Info'
            url='https://flipthat.mondocoolstudios.com'
        />
        </Col>
        <Col lg={8} md={10} sm={24} xs={24}>
        <ProductCard
            photos={[
              '/img/showcase-wr.png'
            ]}
            productName='Whale Radio'
            description='The easiest way to listen to soothing whale songs - this app brings you curated sounds of nature'
            buttonText='Click for More Info'
            url='https://whaleradio.mondocoolstudios.com'
        />
        </Col>
      </Row>
      <div id="TheTeam"></div>
      <ContentBlock
        type="left"
        title={AboutUsBlock.title}
        content={AboutUsBlock.text}
        icon="happy-developer.svg"
      />
      <div id="ContactUs"></div>
      <ContentBlock
        type="right"
        title={ContactBlock.title}
        content={ContactBlock.text}
        url={ContactBlock.url}
        icon="Discord-Logo+Wordmark-Black.svg"
      />
    </Container>
  );
};

export default Home;
