import { createGlobalStyle } from 'styled-components';

const Styles = createGlobalStyle`

    body,
    html,
    a {
        font-family: 'Ubuntu', sans-serif;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
    }

    a:hover {
        color: #000;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Ubuntu', sans-serif;
        color: #0a1f44;
        font-size: 2.575rem;
        line-height: 3.0625rem;
      
        @media only screen and (max-width: 414px) {
          font-size: 1.625rem;
        }
    }

    p {
        color: #343D48;
        font-size: 1.125rem;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #0063B0;

        :hover {
            color: #0063B0;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        padding: 1.25rem;
        text-align: left;
        padding-top: 2.5rem;
        padding-right: 2rem;
    }

    .anticon,
    .ant-notification-notice-icon-success {
        color: rgb(0,99,176);
    }

    .links {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        font-size: 24px;
      
        a {
          text-decoration: none;
          margin: 1rem;
        }
    }

    .card-container {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
      
        @media screen and (max-width: 768px) {
          flex-flow: column;
        }
    }

    .product-card-description-box {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
      
        flex: 0 0 auto;
        height: 50%;
        padding: 1.5rem 2rem;
        box-sizing: border-box;
        background-color: #ecf0f1;
      
        .row {
          display: flex;
          flex-flow: row;
          margin-top: 0.5rem;
        }
      }
      
      .product-card-name {
        text-transform: uppercase;
        font-size: 1.5rem;
        letter-spacing: 3px;
        color: #2c3e50;
      }
      
      .product-card-description {
        color: #2c3e50;
        font-size: 0.75rem;
        letter-spacing: 2px;
      }
      
      .buy-button {
        padding: 0.75rem 1.5rem;
        font-size: 0.75rem;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 3px;
        color: #ecf0f1;
        background-color: #1abcFF;
        border-radius: 0.25rem;
        transition: 0.25s;
      
        &:hover,
        &:focus,
        &:active {
          background-color: #16a0FF;
        }
      }
`;

export default Styles;
