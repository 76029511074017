import React from 'react';
import loadable from '@loadable/component';

import Footer from '../components/Footer';
import Header from '../components/Header';
import GlobalStyles from '../globalStyles';

const MiddleBlock = loadable(() => import('../components/MiddleBlock'));
const Container = loadable(() => import('../common/Container'));
const SvgIcon = loadable(() => import('../common/SvgIcon'));

const NotFound = () => {
  return (
    <React.Fragment>
      <GlobalStyles />
      <Header />
      <Container>
      <MiddleBlock title="Not Found" content="Somethings not right... So we couldn't find what you're looking for."/>
      <SvgIcon src="404-3.svg" />
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default NotFound;